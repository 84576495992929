/* eslint-disable */

import CapChart from "components/CapChart";
import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import WoodenSection from "layouts/WoodenSection";


function formatNumberWithCommas(number) {
  return number.toLocaleString(undefined, { maximumFractionDigits: 0 }); // You can adjust the fraction digits as needed
}

const CapitalGrowthCalculator = () => {
 
  const [avgRate, setAvgRate] = useState(0);
  const [capitalGrowth, setCapitalGrowth] = useState(0);
  const [propertyValue, setPropertyValue] = useState(0);


  const [dataState, setDataState] = useState(
    {
        "labels": [
            "0 Years",
            "1 Year",
            "2 Years",
            "3 Years",
            "4 Years",
            "5 Years",
            "6 Years",
            "7 Years",
            "8 Years",
            "9 Years",
            "10 Years",
            "11 Years",
            "12 Years",
            "13 Years",
            "14 Years",
            "15 Years"
        ],
        "datasets": [
            {
                "data": [
                    500000,
                    525000,
                    551250,
                    578812.5000000001,
                    607753.1250000001,
                    638140.7812500002,
                    670047.8203125002,
                    703550.2113281252,
                    738727.7218945315,
                    775664.1079892581,
                    814447.3133887211,
                    855169.6790581571,
                    897928.163011065,
                    942824.5711616183,
                    989965.7997196993,
                    1039464.0897056843
                ],
                "label": "Capital Growth",
                "borderColor": "#c7c7c7",
                "backgroundColor": "#000000",
                "fill": true
            }
        ]
    }
  );


  const [timeSeries, setTimeSeries] = useState([
    "0 Years",
    "1 Year",
    "2 Years",
    "3 Years",
    "4 Years",
    "5 Years",
    "6 Years",
    "7 Years",
    "8 Years",
    "9 Years",
    "10 Years",
    "11 Years",
    "12 Years",
    "13 Years",
    "14 Years",
    "15 Years"
]);
  const [investmentVals, setInvestmentVals] = useState([]);
  const [growthVals, setGrowthVals] = useState([]);

  const calculateResults = (e) => {


    e.preventDefault();

    const formData = new FormData(e.target);


    const principal = parseFloat(formData.get("principal").replace(/[$,]/g, ''));
    const rate = parseFloat(formData.get("rate").replace(/[$,]/g, ''));
    const time = parseInt(formData.get("time").replace(/[$,]/g, ''));


    window.console.log(principal, rate, time);

    const parsedPrincipal = parseFloat(principal);
    const parsedRate = parseFloat(rate);
    const parsedTime = parseFloat(time);

    const newTimeSeries = [];
    const newInvestmentVals = [];
    const newGrowthVals = [];

    for (let i = 0; i <= parsedTime; i++) {
      newTimeSeries.push(`${i} Year${i != 1 ? 's' : ''}`);
      newInvestmentVals.push(parsedPrincipal);
      newGrowthVals.push(parsedPrincipal * ((1 + parsedRate / 100) ** i));
    }

    setTimeSeries(newTimeSeries);
    setInvestmentVals(newInvestmentVals);
    setGrowthVals(newGrowthVals);

    const newDataSet = {
        labels: newTimeSeries,
        datasets: [
          {
            data: newGrowthVals,
            label: 'Capital Growth',
            borderColor: '#c7c7c7',
            backgroundColor: '#000000',
            fill: true,
          },
        ]
      }


    setDataState(newDataSet);

    window.console.log(newTimeSeries)

    

    const newAvgRate = (((1 + parsedRate / 100) ** parsedTime - 1) * parsedPrincipal) / parsedTime;
    const newCapitalGrowth = parsedPrincipal * ((1 + parsedRate / 100) ** parsedTime - 1);
    const newPropertyValue = parsedPrincipal + newCapitalGrowth;

    setAvgRate(formatNumberWithCommas(newAvgRate));
    setCapitalGrowth(formatNumberWithCommas(newCapitalGrowth));
    setPropertyValue(formatNumberWithCommas(newPropertyValue));
  };  

  const handleCalculate = (e) => {
    e.preventDefault();
    calculateResults();
  };

  const isSSR = typeof window === "undefined"


  return (
    <>
      <section>
        <div className="section-container two-columns">
          <div>
            <p className="fs-24px text-wodden-purple font-helvetica mb-6 lg:mb-1_8vw font-bold">
              Capital Growth Calculator{" "}
            </p>
            <form className="space-y-3" onSubmit={calculateResults}>
              <CurrencyInput
                decimalsLimit={0}
                prefix="$"
                className="font-helvetica py-2 lg:py-0_8vw border-b-2 text-wodden-purple border-gray-1 fs-16px bg-transparent block outline-none w-full"
                name="principal"
                placeholder="Initial Property Investment Value ($)"
              />
              <input 
                type="number" 
                className='font-helvetica py-2 lg:py-0_8vw border-b-2 text-wodden-purple border-gray-1 fs-16px bg-transparent block outline-none w-full' 
                name="rate"
                placeholder="Anticipated Rate Of Capital Growth (%)"
              />
              <input 
                type="number" 
                className='font-helvetica py-2 lg:py-0_8vw border-b-2 text-wodden-purple border-gray-1 fs-16px bg-transparent block outline-none w-full' 
                name="time"
                placeholder="Investment Term (Years)"
              />
              <div className="inline-block pt-4">
                <button
                  className="btn-primary-default"
                  type="submit"
                >
                  Calculate
                </button>
              </div>
            </form>
          </div>
           <div className="aspect-square pb-12">
            {
              timeSeries!= null && timeSeries.length > 0 && dataState.datasets[0].data.length > 0 && !isSSR ? (
                <CapChart data={dataState} key={capitalGrowth} totalSavings={capitalGrowth} title={`Capital Growth`}/>
              ) : null
            }
          </div> 
        </div>
      </section>
      <section className="bg-gray-1 py-10 lg:py-4vw">
      <div className="section-container grid lg:grid-cols-3 gap-4 text-center">
          <div class="flex justify-between flex-col">
            <p className="lg:text-center  fs-24px mb-4 lg:max-w-xs mx-auto">Average Capital Growth Per Year:</p>
            <p className="text-[2.5rem] lg:text-3xl xl:text-5xl font-bold ">${formatNumberWithCommas(avgRate)}</p>
          </div>
          <div class="flex justify-between flex-col">
            <p className="lg:text-center fs-24px mb-4 lg:max-w-xs mx-auto">Capital Growth Over Life Of Investment:</p>
            <p className="text-[2.5rem] lg:text-3xl xl:text-5xl font-bold ">${formatNumberWithCommas(capitalGrowth)}</p>
          </div>
          <div class="flex justify-between flex-col">
            <p className="lg:text-center  fs-24px mb-4 lg:max-w-xs mx-auto">Your Total Property Value At Retirement:</p>
            <p className="text-[2.5rem] lg:text-3xl xl:text-5xl font-bold ">${formatNumberWithCommas(propertyValue)}</p>
          </div>
      </div>
      </section>
    </>
  );
};

export default CapitalGrowthCalculator;
