import Accordion from "components/Accordion";
import Logo from "components/Logo";
import useMediaQuery from "hooks/useMediaQuery";
import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { graphql, useStaticQuery } from "gatsby";
import useStoryblok from "lib/storyblok";

import Link from "utils/link";
const TrustpilotWidget = ({location}) => {

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);


  return (
    <div id="trustpilot-widget" className="trustpilot-widget bg-wodden-purple !text-white pt-[4px]" data-locale="en-GB" data-template-id="5406e65db0d04a09e042d5fc" data-businessunit-id="5ea2220132f9b6000135a6c4" data-style-height="28px" data-style-width="100%" data-theme="dark">
      <a href="https://uk.trustpilot.com/review/wtcapital.com.au" target="_blank" rel="noopener noreferrer"></a>
    </div>
  );
}
function MobileBurger(props) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-0.5 0 24 15"
      className="h-[16px] w-auto"
      {...props}
    >
      <path stroke="currentColor" d="M23 0.5L-4.37114e-8 0.499998" />
      <path stroke="currentColor" d="M23 14.5L-4.37114e-8 14.5" />
    </svg>
  )
}

function Navbar({location}) {
  const [isMenuVisibile, setMenuVisibility] = useState(false);
  const [isNavbarScrolled, setNavbarScrolled] = useState(false);
  const accordionMenuRef = useRef(null);
  const MOBILE_NAV_HEIGHT = "60px";
  const isAbove1024px = useMediaQuery("(min-width: 1024px)");


  const data = useStaticQuery(graphql`
    query NavQuery {
      storyblokEntry(full_slug: { eq: "header" }) {
        id
        content
      }
    }
  `);

  let story = data.storyblokEntry;

  story = useStoryblok(story);

  const content = story.content;



  useEffect(() => {
    if (isMenuVisibile) {
      accordionMenuRef.current.style.height = `calc(${
        isAbove1024px
          ? accordionMenuRef.current.scrollHeight
          : accordionMenuRef.current.scrollHeight
      }px - ${isAbove1024px ? "0px" : "0px"})`;

      if (!isAbove1024px) {
        document.body.style.overflowY = "hidden";
      }
    } else {
      accordionMenuRef.current.style.height = 0;
      if (!isAbove1024px) {
        document.body.style.overflowY = "scroll";
      }
    }
  }, [isMenuVisibile]);

  useEffect(() => {
    const handler = () => {
      if (window.scrollY > 100) {
        setNavbarScrolled(true);
        // translate the nav element up by the height of the div with id trustpilot-widget

        // get the height of the trustpilot widget

        const navbar = document.getElementById("navbar");

        //translate the navbar up by the height of the trustpilot widget

        navbar.style.transform = `translateY(-${0}px)`;

      } else {
        setNavbarScrolled(false);
        const navbar = document.getElementById("navbar");

        // translate the nav element back to its original position
        navbar.style.transform = `translateY(0px)`;

      }
    };

    // If esc key is pressed, close the menu

    const keyHandler = (e) => {
      if (e.key === "Escape") {
        setMenuVisibility(false);
      }
    };

    // if the user clicks outside the menu, close the menu


    document.addEventListener("keydown", keyHandler);

    document.addEventListener("scroll", handler);


    return () => {document.removeEventListener("scroll", handler); window.removeEventListener("keydown", keyHandler);};
  });

  const col_one = content.col_one;
  const col_two = content.col_two;
  const col_three = content.col_three;

  const mobileNumber = content.mobile_number.cached_url
  const email = content.email.cached_url

  // strip tel and mailto from the string

  const mobileNumberStripped = mobileNumber.replace(/tel:|mailto:/g, "");
  const emailStripped = email.replace(/tel:|mailto:/g, "");

  const mobile_links = content.mobile_menu;

  const isSsr = typeof window === "undefined";


  return (
    <nav
      className={`fixed top-0 left-0 z-[100] w-full transition-all delay-100 duration-300 overflow-y-auto ${
        isMenuVisibile
          ? "bg-wodden-purple rounded-b-[30px]"
          : isNavbarScrolled
          ? "bg-wodden-purple"
          : "bg-transparent"
      }`}
      id="navbar"
    > 
    <TrustpilotWidget location={location} />
      <div className="section-container w-full py-[40px] lg:h-[7.32vw] flex items-center justify-between ">
        <div className="relative">
          <button
            className={`text-white text-3xl transition-all duration-300 absolute top-1/2 left-0 -translate-y-1/2 ${
              isMenuVisibile
                ? "opacity-100 pointer-events-auto"
                : "opacity-0 pointer-events-none"
            }`}
            onClick={() => setMenuVisibility(false)}
          >
            <span className="sr-only">Close</span>
            <IoClose />
          </button>

          <button
            className={`fs-20px text-white outline-none transition-all duration-300 flex items-center justify-center nav-menu ${
              !isMenuVisibile
                ? "opacity-100 pointer-events-auto"
                : "opacity-0 pointer-events-none"
            }
            ${isNavbarScrolled ? "!text-white" : ""}`
          }
            onClick={() => setMenuVisibility(true)}
          >
            <span className='sr-only'>Menu</span>
            <span className='hidden lg:inline-block'>Menu</span>
            <span className={`flex items-center justify-center lg:hidden ${isNavbarScrolled ? "!text-white" : ""}`}>
              <MobileBurger />
            </span>
          </button>
        </div>

        <Link to="/" className={`text-white uppercase nav-menu ${isMenuVisibile ? 'nav-menu-visible' : ''}  ${isNavbarScrolled ? "!text-white" : ""}`}><Logo/></Link>
      </div>
      <div
        ref={accordionMenuRef}
        className={`h-0 scrollbar-hide ${
          isMenuVisibile ? "overflow-auto" : "overflow-hidden"
        } transition-all duration-300`}
      >
        <div className="mt-2 lg:mt-[min(1rem,0.8vw)] section-container nav-links">
          {isAbove1024px ? (
            <div className="py-8 lg:py-[min(6rem,3vw)] border-t-2 border-white grid gap-9 lg:gap-0 lg:grid-cols-[1fr_1fr_auto]">
              <div className="space-y-3 lg:space-y-1_4vw">
                {col_one.map((item, index) => (
                  <Link to={item.link.cached_url} key={`nav-1-${index}`} className="fs-16px text-white block w-fit font-helvetica">
                    {item.title}
                  </Link>
                ))}
       
                
              </div>

              <div className="space-y-3 lg:space-y-1_4vw">
              {col_two.map((item, index) => (
                  <Link to={item.link.cached_url} key={`nav-2-${index}`} className="fs-16px text-white block w-fit font-helvetica">
                    {item.title}
                  </Link>
                ))}
              </div>

              <div className="flex flex-col justify-between">
                <div className="space-y-3 lg:space-y-1_4vw mb-6">
                {col_three.map((item, index) => (
                  <Link to={item.link.cached_url} key={`nav-3-${index}`} className="fs-16px text-white block w-fit font-helvetica">
                    {item.title}
                  </Link>
                ))}
       
                </div>

                <div className="space-y-3 lg:space-y-1_4vw">
                <a
                    href={email}
                    className="fs-16px text-white block w-fit font-helvetica"
                  >
                    {emailStripped}
                  </a>
                  <a
                    href={mobileNumber}
                    className="fs-16px text-white block w-fit font-helvetica"
                  >
                    {mobileNumberStripped}
                  </a>
                
                </div>
              </div>
            </div>
          ) : (
            <div className="py-8 border-t-2 border-white grid gap-5">


              {
                mobile_links.map((item, index) => {
                  if (item.component === "link") {
                    return (
                      <Link to={item.link.cached_url} key={`nav-1-${index}`} className="fs-16px text-white block w-fit font-helvetica">
                        {item.title}
                      </Link>
                    )
                  }else{
                    return(
                      <Accordion
                      title={item.title}
                      buttonClassName="flex items-center justify-between w-full space-x-6 text-left fs-16px text-white"
                      className=""
                    >
                      <div className="space-y-3 lg:space-y-1_4vw pl-3 pt-2">
                        {item.links.map((sub_item, sub_index) => (
                          <Link to={sub_item.link.cached_url} key={`nav-1-${index}-${sub_index}`} className="fs-16px text-white block w-fit font-helvetica">
                            {sub_item.title}
                          </Link>
                        ))}
                      </div>
                    </Accordion>
                    
                    )
                  }
                })
              }


              

              <div className="space-y-2 pt-3">
                <a
                  href={email}
                  className="fs-16px text-gray-1 block w-fit font-helvetica"
                >
                  {emailStripped}
                </a>
                <a
                  href={mobileNumber}
                  className="fs-16px text-gray-1 block w-fit font-helvetica"
                >
                  {mobileNumberStripped}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;