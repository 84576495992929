import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import BlogCard from "./BlogCard";

const BlogsAll = () => {
  const data = useStaticQuery(graphql`
    {
      posts: allStoryblokEntry(filter: { field_component: { eq: "blog" } }) {
        edges {
          node {
            id
            content
            full_slug
          }
        }
      }
    }
  `);

  const itemsPerPage = 8; // Items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedPosts, setDisplayedPosts] = useState([]);

  useEffect(() => {
    const newPosts = data.posts.edges.slice(0, itemsPerPage * currentPage);
    setDisplayedPosts(newPosts);
  }, [currentPage, data.posts.edges]);

  const loadMore = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const hasMorePosts = displayedPosts.length < data.posts.edges.length;

  return (
    <>
      <section className="section-container -mx-[2.6875rem] md:mx-auto">
        <div className="grid md:grid-cols-3 gap-10 -mb-12 pt-14 lg:-mb-8 lg:pt-28 lg:pb-10">
          {displayedPosts.map((article, index) => {
            const spanFull = index === 0 || index % 4 === 0;
            const parsedData = JSON.parse(article.node.content);
            return (
              <BlogCard
                key={article.node.id}
                title={parsedData.headline}
                category={article.category}
                image={parsedData.image.filename}
                link={`/${article.node.full_slug}`}
                className={`${spanFull ? "md:col-span-3" : "col-span-1"}`}
              />
            );
          })}
        </div>
        {hasMorePosts && (
          <div className="text-center mt-16 lg:mt-8">
            <button onClick={loadMore} className="btn-primary-default inline-block text-center">
              Load More
            </button>
          </div>
        )}
      </section>
    </>
  );
};

export default BlogsAll;
