import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    defaults 
  } from 'chart.js';
  
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

defaults.font.family = 'sculpin';



const labels = [
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    58,
    59,
    60,
    61,
    62,
    63,
    64,
    65,
    66,

];


const CapChart = (props) => {

  const options = {
    aspectRatio: 1,
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: props.title ? props.title : 'Retirement Calculator',
    },
    legend: {
        display: false,
    }
  },
};

    return(
        <Line options={options} data={props.data} aspectRatio={1} key={props.totalSavings} redraw={true}/>
    )
}

export default CapChart