import React from "react"
import { sbEditable } from '@storyblok/storyblok-editable'
import Hero from "sections/generic/hero"
import DualTextWithCta from "sections/generic/dualTextWithCta"
import TrustPilot from "sections/home/trustPilot"
import Testimonials from "sections/home/testimonials"
import CtaBlock from "sections/generic/ctaBlock"
import TextWithImage from "sections/generic/textWithImage"
import TestimonialSmallHeight from "sections/generic/testimonialSmallHeight"
import FaqSection from "sections/generic/faqSection"
import TeamMembers from "sections/about/teamMembers"
import ContactDummy from "sections/contact/ContactDummy"
import CaseStudy from "sections/generic/caseStudy"
import RetirementCalculator from "sections/generic/Chart"
import CapitalGrowthCalculator from "sections/generic/CapitalGrowthCalc"
import BlogsAll from "./AllBlogs"

const Components = {
  "hero": Hero,
  "dual_text_with_cta": DualTextWithCta,
  "trustpilot_banner": TrustPilot,
  "testimonials": Testimonials,
  "cta_block": CtaBlock,
  "text_with_image": TextWithImage,
  "testimonial_small_height": TestimonialSmallHeight,
  "faq_section": FaqSection,
  "team_members": TeamMembers,
  "contact_dummy": ContactDummy,
  "case_study": CaseStudy,
  "retirement_calculator": RetirementCalculator,
  "capital_growth_calculator": CapitalGrowthCalculator,
  "blogs_all": BlogsAll
}
 
const DynamicComponent = ({ blok }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return (<div {...sbEditable(blok)} className={`h-full w-full max-w-[100vw] lg:max-w-[unset] ${blok.component != "capital_growth_calculator" ? `mb-[min(120px,13vh)] lg:mb-7vw` : ``}`}><Component blok={blok} /></div>)
  }
  return (<p>The component <strong>{blok.component}</strong> has not been created yet.</p>)
}
 
export default DynamicComponent
