import * as React from "react"

function Logo(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1787.96 149.23"
      {...props}
      className='logo'
    >
      <defs>
        <style>{".cls-1{fill:currentColor}"}</style>
      </defs>
      <path
        className="cls-1"
        d="M239.15 2.87L239.15 25.02 297.59 25.02 297.59 146.36 322.2 146.36 322.2 25.02 380.64 25.02 380.64 2.87 239.15 2.87z"
      />
      <path
        className="cls-1"
        d="M196.56 2.87L164.06 134.63 131.55 2.87 113.23 2.87 107.44 2.87 89.12 2.87 56.62 134.63 24.1 2.87 0 2.87 35.4 146.36 53.72 146.36 59.5 146.36 77.83 146.36 110.33 14.61 142.83 146.36 161.16 146.36 166.95 146.36 185.28 146.36 220.68 2.87 196.56 2.87z"
      />
      <path
        className="cls-1"
        d="M838.11 22.15c26.76 0 43.52 15.43 49.42 35.42h25.48C906.09 25.07 879.18 0 838.11 0c-47.98 0-76.69 34.25-76.69 74.64s28.71 74.59 76.69 74.59c41.06 0 67.98-25.07 74.9-57.57h-25.48c-5.89 19.95-22.65 35.43-49.42 35.43-34.03 0-51.88-25.02-51.88-52.45s17.85-52.5 51.88-52.5M1200.05 71.36h-53.12V24.61h53.12c17.84 0 29.53 8.82 29.53 23.17s-11.69 23.58-29.53 23.58m1.23-68.49h-78.96v143.49h24.61V92.84h54.35c33.21 0 52.9-18.4 52.9-44.86s-19.28-45.12-52.9-45.12"
      />
      <path
        className="cls-1"
        d="M1286.79 2.88H1311.3899999999999V146.37H1286.79z"
      />
      <path
        className="cls-1"
        d="M1339.28 2.87L1339.28 25.02 1397.73 25.02 1397.73 146.36 1422.33 146.36 1422.33 25.02 1480.77 25.02 1480.77 2.87 1339.28 2.87z"
      />
      <path
        className="cls-1"
        d="M1670.05 2.87L1670.05 146.36 1787.96 146.36 1787.96 124.22 1694.65 124.22 1694.65 2.87 1670.05 2.87z"
      />
      <path
        className="cls-1"
        d="M1011.29 14.04l31.56 79.21h-63.13l31.56-79.21zm4.45-11.17h-27.22l-57.17 143.49h27.21l12.1-30.36h81.27l12.1 30.36h27.23L1034.07 2.87h-18.32zM1560.72 14.04l31.56 79.21h-63.13l31.56-79.21zm4.46-11.17h-27.23l-57.18 143.49H1508l12.1-30.36h81.27l12.1 30.36h27.23L1583.49 2.87h-18.31z"
      />
    </svg>
  )
}

export default Logo
