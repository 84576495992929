import { Link } from "gatsby";
import React from "react";

const BlogCard = ({ title, category, image, link, className }) => {
  return (
    <div
      className={`flex flex-col gap-8 font-medium pb-6 relative ${className}`}
    >
      <div className="aspect-[430/260] md:aspect-[730/385]">
        <img src={image} alt={title} className="object-cover h-full w-full" />
      </div>
      <div className="text-lg lg:text-2xl px-[2.6875rem] md:px-0">
        <h3>{title}</h3>
      </div>
      <p className="px-[2.6875rem] md:px-0">
        <span> Read more</span>
        <Link
          href={link}
          className="after:content-[''] after:absolute after:inset-0"
        >
          <span className="sr-only">{title}</span>
        </Link>
      </p>
    </div>
  );
};

export default BlogCard;
