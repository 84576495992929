import React, { useState, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import useStoryblok from "lib/storyblok";
import Link from "utils/link";

function Footer() {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      storyblokEntry(full_slug: { eq: "footer" }) {
        id
        content
      }
    }
  `);

  let story = data.storyblokEntry;

  story = useStoryblok(story);

  const content = story.content;

  const mobileNumber = content.phone_number.cached_url;
  const email = content.email.cached_url;

  // strip tel and mailto from the string

  const mobileNumberStripped = mobileNumber.replace(/tel:|mailto:/g, "");
  const emailStripped = email.replace(/tel:|mailto:/g, "");

  const social_links = content.social_links;

  const sitemap = content.sitemap;

  const tagline = content.tagline;

  const email_subline = content.email_subline;

  const [formSubmitted, setFormSubmitted] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    let myForm = formRef.current;
    let formData = new FormData(myForm);
    if (formSubmitted){
      return;
    }
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => console.log("Form successfully submitted"))
      .catch((error) => alert(error));

    setFormSubmitted(true);
  };

  return (
    <footer className="bg-wodden-purple py-16 lg:py-5vw">
      <div className="section-container grid grid-cols-1 gap-8 lg:gap-0 lg:grid-cols-[repeat(3,.6fr)_1fr]">
        <div>
          <p className="fs-14px uppercase mb-5 lg:mb-1_4vw text-white font-bold">
            WT CAPITAL PTY LTD
          </p>

          <div className="space-y-2 lg:space-y-0_7vw">
            <p className="fs-14px text-gray-1 font-helvetica">{content.abn}</p>
            <a className="fs-14px text-gray-1 font-helvetica" href={email}>
              {emailStripped}
            </a>
            <a
              className="fs-14px text-gray-1 font-helvetica"
              href={mobileNumber}
            >
              {mobileNumberStripped}
            </a>
          </div>
        </div>

        <div>
          <p className="fs-14px mb-5 lg:mb-1_4vw text-white font-helvetica font-medium">
            Follow Us
          </p>

          <div className="space-y-2 lg:space-y-0_7vw">
            {social_links.map((link) => (
              <Link
                key={link._uid}
                className="fs-14px text-gray-1 font-helvetica"
                to={link.link.cached_url}
              >
                {link.title}
              </Link>
            ))}
          </div>
        </div>

        <div>
          <p className="fs-14px mb-5 lg:mb-1_4vw text-white font-helvetica font-medium">
            Sitemap
          </p>

          <div className="space-y-2 lg:space-y-0_7vw">
            {sitemap.map((link) => (
              <Link
                className="fs-14px text-gray-1 font-helvetica"
                to={link.link.cached_url}
                key={link._uid}
              >
                {link.title}
              </Link>
            ))}
          </div>
        </div>

        <div className="lg:col-span-1 pt-6 lg:pt-0 flex justify-between flex-col">
          <p className="fs-14px mb-6 lg:mb-[15px] text-white font-helvetica font-medium">
            {tagline}
          </p>

          <p className="fs-14px text-gray-1 mb-3 lg:mb-1vw font-helvetica">
            {email_subline}
          </p>

          <form
            onSubmit={handleSubmit}
            ref={formRef}
            data-netlify="true"
            method="post"
            name="newsletter"
          >
            <input type="hidden" name="form-name" value="newsletter" />

            <input
              type="email"
              name="email"
              required
              placeholder="Enter email address here & press enter"
              className="font-helvetica py-2 lg:py-[8px] border-b-2 text-gray-1 border-gray-1 text-[0.875rem] lg:text-[min(0.57vw,10px)] bg-transparent block outline-none w-full placeholder-gray-1"
            />
            
          </form>
          {formSubmitted && (
          <p className="fs-14px mt-8 text-white font-bold mb-3 lg:mb-1vw font-helvetica">
          Submitted
        </p>
          )}
          <p className="text-[8px] lg:text-[min(0.46vw,8px)] text-gray-1 pt-[45px] lg:pt-[18px]">
          Any financial and legal advice offered by the Author of this website is of a general nature and may not be suitable for some people. No individual financial or legal needs or goals have been taken into account in the advice offered, you should always seek independent advice about specific financial and legal decisions. The Author of this website disclaims all and any responsibility or liability in respect of information detailed or omitted (or the consequences thereof) from this website or any other meetings held with the author
          </p>

        </div>
      </div>
    </footer>
  );
}

export default Footer;
